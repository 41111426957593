const messagingConfig = {
  messages: {
    error: {
      unknown_error: "Oops! Something went wrong. Please try again later.",
      non_authenticated_error: "You need to be logged in to continue.",
    },
    not_loggedIn: {
      text: "Login to interact with the app.",
    },
    resumeParser: {
      text: "Parse a word or PDF doc of size up to 5 MB",
    },
  },
};

export { messagingConfig };
